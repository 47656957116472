.AboutUsBnner
{
    margin:50px 0px ;
}
.AboutUS .DetailsSec .leftSec p:nth-child(3) {
    font-weight: 600 !important;
    display: inline-block;
    margin-left: 0px;
}
.AboutUS .DetailsSec .leftSec p:nth-child(1),
.ourGoal .DetailsSec .leftSec p:nth-child(1) {
    color: #273889;
}
.AboutUS .verticalBorder,
.Mission .verticalBorder,
.ourGoal .verticalBorder
 {
    border-left: 2px solid #919191;
    height: 16vh;
}

.AboutUS .DetailsSec .leftSec .border,
.Mission .DetailsSec .leftSec .border,
.ourGoal .DetailsSec .leftSec .border
{
    background: #919191;
    height: 2px;
    border: none !important;
}
.AboutUS,.Mission,.ourGoal
{
    padding: 0% 8%;
}

.Mission
{
    background-image: url(../../public/Images/AboutwriteUS.webp);
    background-size: 100% 100%;
}

@media screen and (max-width: 575.5px) 
{
    .AboutUsBnner img
    {
        width: 100%;
    }
}
