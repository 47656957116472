.LandingPageHeader
{
    background-image: url(../../public/Images/headerSlider3.png);
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.LandingPageHeader2
{
    height: 100vh;
    width: 100%;
    display: flex;
    background-color: #273889;
}
.headerSlider2
{
    display: flex;
    align-items: end;
}
.hederSec .swiper 
{
    height: 100vh !important;
}
.HeaderText
{
    padding-left: 10%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.productCetagory .row .col-md-3:nth-child(2)
{
    border-right:1px solid black ;  
    border-left:1px solid black ;  
}
.categoryEach
{
    padding:6vh 0px
}
.categoryEach a
{
    display: flex;
    align-items:center;
}
.categoryEach a:hover {
    transform: scale(1.2);
  }
  .categoryEach a {
    transition: 0.4s;
  }
.categoryEach a p
{
    text-align:left;
}


.WideRange
{
    background-image: url(../../public/Images/wideRange.webp);
    width: 100%;
    background-size: cover;
    padding: 0% 5%;
}
.productDetailsSec
{
    padding: 0% 5%;
    margin-bottom: 75px;
}
.productDetailsSec .rightSec
{
    padding: 75px 0px 0px 0px;
}
.bestProductClass
{
    padding: 5% 5%;
    background-color: #283989;
}
.DieselEngineOil .MobImage
{
    display: none;
}

.bestProductClass .leftSec .BestProductHead p span:nth-child(1)
{
    font-weight: 300 !important;
    font-size: 3rem;
}
.bestProductClass .leftSec .BestProductHead p 
{
    font-weight: 100 !important;
    font-size: 4rem !important;
    display: contents;
    text-transform: uppercase;
}
.bestProductClass .leftSec .BestProductHead p span
{
    font-weight: 600 !important;
    font-size: 4rem;
}
.bestProductClass .BestProductHead .border {
    width: 25%;
    margin-top: 35px;
    margin-bottom: 35px;
}
.bestProductClass .RightSec img
{
width: 100%;
}


.bestSellerSlider
{
    padding-left: 5%;
}
.bestSellerSlider .sliderHead
{
    margin: 35px 0px;
}
.productdetail p:nth-child(2) span
{
    color: #273889;
    font-weight: 700;
}

.bestSellerSlider .swiper-slide {
    width: 50%;
    margin: 0 0px 0px 10px;
  }
  
  .swiper-slide-next,
  .swiper-slide-prev {
    width: 75%;
  }
  .bestSellerSlider .swiper 
  {
    position: relative !important;
    padding: 0px 50px !important;
  }
  .bestSellerSlider .swiper-pagination
  {
    position: absolute !important;
    bottom: 34px !important;
  }

  .AboutSec
{
    background-image: url(../../public/Images/aboutBG.webp);
    width: 100%;
    background-size: cover;
    padding: 0% 5%;
}

.LandingFooter .footerTop
{
    background-color: transparent;
    border-bottom: 3px solid #273889;
}
.LandingFooter .footerTop .socialMedia a svg {
    margin: 0px 8px;
    fill: black;
}



/* Media Queries for small Devices */

@media screen and (max-width: 767.5px) 
{
    .categoryEach a 
    {
        flex-direction: column;
    }
    .categoryEach a p {
        text-align: center;
        margin: 15px 0px 0px 0px;
    }
    .LandingPageHeader
    {
        background-image: url(../../public/Images/Mobslider4.png);
        background-position: top;
    }
    .LandingPageHeader2
    {
        background-image: url(../../public/Images/Mobslider5.png);
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .headerSlider2 img
    {
        display: none;
    }
    .HeaderText {
        justify-content: end;
        padding-bottom: 38px;
        line-height: 1.2;
    }
    .NavbarMAin::before {
        transform: skew(6deg);
    }
    .productDetailsSec .row .col-md-5:nth-child(2)
    {
        padding-left: 25px ;
    } 
}

/* Media Queries for extra small Devices */

@media screen and (max-width: 575.5px) 
{
    .productDetailsSec .row .col-md-5:nth-child(2)
    {
        padding-left: 0px ;
    } 
    .DetailsSec .leftSec {
        padding-bottom: 8vh !important;
        padding-top: 8vh !important;
    }
    .DetailsSec .RightSec
    {
        padding-bottom: 8vh;
    }
    .DieselEngineOil .MobImage
    {
        display: block;
    }
    .DieselEngineOil .rightSec
    {
        display: none;
    }
    .DieselEngineOil .verticalBorderText
    {
        margin: 50px 0px;
    }

    .GearOil .verticalBorderText
    {
        margin-top: 50px;
    }
    .productDetailsSec .rightSec {
        padding: 50px 0px 0px 0px;
    }
    .bestSellerSlider .swiper {
        padding: 0px 50px 0px 0px !important;
    }
    .EachProduct .slideritems {
        padding: 15px !important;
    }
    .EachProduct .sliderimage {
        width: 100% !important;
        padding-right: 0px !important;
    }
    .bestSellerSlider .swiper {
        height:70vh !important;
    }
    .bestSellerSlider .EachProduct .slideritems 
    {
        min-height: 58vh !important;
        max-height: 58vh !important;
    }
}