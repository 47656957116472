.NavbarMAin::before
{
    background-color:#F4F4F4;
}
.SizeFilter input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    margin: 15px 0px;
    
  }
label
{
    font-size: 2rem;
    margin-left: 15px;
    white-space: nowrap;
}

/* .abc:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    border: 3px solid #A9A9A9;
    border-radius: 0px;
} */

.FilerSideBar
{
    background-color: #F4F4F4;
    width: 320px;
    padding: 5% 3%;
    height: fit-content;
}
.SizeFilter
{
    margin-top: 25px;
}
.ProductsList
{
    padding: 0% 2%;
}
#gearoil,#diesel,#gasoline,.AllOils,.Compatable,#Transmissionoil,#Flushoil
{
    padding-top: 10%;
}
#gearoil .ProductHeding,
#diesel .ProductHeding,#gasoline .ProductHeding,
.AllOils .ProductHeding,.Compatable .ProductHeding,
#Transmissionoil .ProductHeding,
#Flushoil .ProductHeding
{
    border-bottom: 2px solid #D6D6D6;
    padding-bottom: 5px;
    margin-bottom: 35px;
}
.sae90 .EachProduct .sliderimage img
{
    width: 10vw !important;
}

#Transmissionoil .EachProduct .sliderimage,
#diesel .EachProduct .sliderimage,
#Flushoil .EachProduct .sliderimage,
#dieselcheck .EachProduct .sliderimage
{
    width: 20% !important;
}

.ProductItems
{
display: grid;
  column-gap: 35px;
  grid-template-columns: auto auto;
  row-gap: 35px;
}


.Gobutton 
{
    position:absolute;
    bottom: 10px;
    right: 19px;
    padding:4px 4px;
    border-radius:2px;
}
.Gobutton:hover
{
    background:#273889;
    outline:2px solid white;
    
}
.Gobutton a i
{
    font-size:1.8rem;
    color:#273889;
    padding:3px 10px;   
    display:flex; 
}
.Gobutton a i svg
{
    width: 16px;
    height: 20px;
}
.Gobutton:hover i
{
    color:white;
    outline:2px solid white;
    
}
.Gobutton:hover i svg
{
    fill:white;
}

@media screen and (max-width: 767.5px) 
{
    .ProductItems {
        grid-template-columns: auto !important;
    }
    .EachProduct .sliderimage {
        padding-right: 20px;
        width: 48%;
    }
}

/* Media Queries for extra small Devices */

@media screen and (max-width: 575.5px) 
{
    .EachProduct .slideritems
    {
        display: block !important;
        min-height:auto !important; 
        max-height:fit-content !important;
    }
    .sliderimage
    {
        display:flex;
        justify-content:center;
        margin-bottom:25px;
    }
    .sliderimage img
    {
        width:50%;
    }
}