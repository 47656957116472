.Footer
{
    margin-top: 85px;
}
.footerBottom
{
    padding: 3% 5%;

}
.footerTop
{
    background-color: #273889;
}
.footerTop .socialMedia a svg
{
    fill: white;
    width:15px;
}
.footerTop .socialMedia a img,
.footerTop .socialMedia a svg
{
    margin: 0px 8px;
}

@media screen and (max-width: 575.5px) 
{
    .FotleftSec p
    {
        margin: 15px 0px 25px 0px;
    }
}
