.SingleProduct .slideritems {
    background: #F4F4F4;
    padding: 60px;
    border-radius: 20px;
    box-shadow: 1px 3px 7px #888888;
    margin-top: 10px;
    min-height: auto !important;
    max-height: auto !important;
}

/* .SingleProduct .Specification
{
    margin-top: 50px;
} */
.SingleProduct .liters
{
    margin-top: 20px;
}
.ProductInfoBtn
{
    margin: 50px 0px;
}
.ProductInfoBtn
{
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto auto;
}

.HowToUse
{
    background: #F4F4F4;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 50px;
}
.HowToUse ul
{
    list-style: auto;
    margin-top: 30px;
    padding-left: 30px;
}
.HowToUse ul li::marker
{
    font-size: 2rem;
}